import React, { Component } from 'react'
import classes from './Wrapper.module.css'
import TitleTransparent from '../TitleTransparent/TitleTransparent'
import SubTitle from '../SubTitle/SubTitle'
import Slider from '../Slider/Slider'
import Contact from '../Contact/Contact'
import {
	Sorter,
	Sorter2,
	Sorter3,
	Sorter4,
	Sorter5,
	Sorter6,
	Sorter7,
	Sorter8,
	Sorter9,
	Sorter10,
	Sorter11,
	Sorter12,
	Sorter13,
	Sorter14,
	Sorter15,
	MLG,
	MLG2,
	MLG3,
	MLG4,
	MLG5,
	MLG6,
	MLG7,
	MLG8,
} from '../../img/screens/exportScreens'

export default class Wrapper extends Component {
	state = {
		name: ['MY LITTLE GENIUS СОРТЕР', 'MY LITTLE GENIUS'],
		transparentName: ['НАШИ РАБОТЫ', 'КОНТАКТЫ'],
		width: ['1200px', '800px'],

		firstSlider: [
			Sorter,
			Sorter2,
			Sorter3,
			Sorter4,
			Sorter5,
			Sorter6,
			Sorter7,
			Sorter8,
			Sorter9,
			Sorter10,
			Sorter11,
			Sorter12,
			Sorter13,
			Sorter14,
			Sorter15,
		],
		secondSlider: [MLG, MLG2, MLG3, MLG4, MLG5, MLG6, MLG7, MLG8],
		contacts: [
			['TELEGRAM', '@sintexnik'],
			['E-MAIL', 'sintexmts@gmail.com'],
			['ТЕЛЕФОН', '+7 (920) 080-05-68'],
		],
	}
	render() {
		return (
			<>
				<TitleTransparent name={this.state.transparentName[0]} />
				<SubTitle name={this.state.name[0]} />
				<Slider Slider={this.state.firstSlider} width={this.state.width[0]} />
				<SubTitle name={this.state.name[1]} />
				<Slider Slider={this.state.secondSlider} width={this.state.width[1]} />
				<TitleTransparent name={this.state.transparentName[1]} />
				<div className={classes.contacts}>
					{this.state.contacts.map((contact, index) => (
						<Contact key={index} name={contact[0]} value={contact[1]} />
					))}
				</div>
			</>
		)
	}
}
