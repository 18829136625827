import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import MyLittleGenisIos from './Policy/MyLittleGenis-ios'
import MyLittleGenisSorterIos from './Policy/MyLittleGeniusSorter-ios'
import MyLittleGenisPuzzlesIos from './Policy/MyLittleGeniusPuzzles-ios'

const app = (
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<App />} />
				<Route path="/mylittlegenis-ios" element={<MyLittleGenisIos />} />
				<Route
					path="/mylittlegenis-sorter-ios"
					element={<MyLittleGenisSorterIos />}
				/>
				<Route
					path="/mylittlegenis-puzzles-ios"
					element={<MyLittleGenisPuzzlesIos />}
				/>
				<Route path="*" element={<Navigate to="/" />} />
			</Routes>
		</BrowserRouter>
	</React.StrictMode>
)

ReactDOM.render(app, document.getElementById('root'))
