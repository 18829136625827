import React from 'react'
import classes from './Background.module.css'

const Background = () => (
	<div className={classes.bg}>
		<div className={classes.grad}></div>
	</div>
)

export default Background
