import classes from './SubTitle.module.css'

import React from 'react'

export default function SubTitle(props) {
	return (
		<>
			<h3 className={classes.title}>
				<span>ПРИЛОЖЕНИЕ </span>
				{props.name}
			</h3>
		</>
	)
}
