import React from 'react'
import classes from './Title.module.css'

const Title = () => {
	return (
		<div className={classes.group}>
			<h1 className={classes.title}>PHOENIX LAB</h1>
			<p className={classes.text}>СТУДИЯ МОБИЛЬНОЙ РАЗРАБОТКИ В ОРЛЕ</p>
		</div>
	)
}

export default Title
