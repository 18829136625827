import './App.css'
import Background from './components/Background/Background'
import Logo from './components/Logo/Logo'
import Title from './components/Title/Title'
import Wrapper from './components/Wrapper/Wrapper'
import Footer from './components/Footer/Footer'
import { useEffect } from 'react'

const App = () => {
	useEffect(() => {
		document
			.querySelector(':root')
			.style.setProperty('--vh', window.innerHeight / 100 + 'px')

		window.addEventListener('resize', () => {
			document
				.querySelector(':root')
				.style.setProperty('--vh', window.innerHeight / 100 + 'px')
		})

		document.addEventListener('fullscreenchange', () => {
			document
				.querySelector(':root')
				.style.setProperty('--vh', window.innerHeight / 100 + 'px')
		})
	}, [])

	return (
		<div className="App">
			<Background />

			<header>
				<Logo />
				<Title />
			</header>

			<main>
				<Wrapper />
			</main>

			<Footer />
		</div>
	)
}

export default App
