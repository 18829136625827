import React from 'react'
import classes from './Logo.module.css'
import LogoSvg from '../../img/LogoSvg'

const Logo = () => {
	return (
		<figure className={classes.figure}>
			<LogoSvg />
		</figure>
	)
}

export default Logo
