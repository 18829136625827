import React from 'react'
import classes from './Contact.module.css'

export default function Contact(props) {
	return (
		<div className={classes.item}>
			<h3 className={classes.title}>{props.name}</h3>
			<p className={classes.info}>{props.value}</p>
		</div>
	)
}
