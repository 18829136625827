import React from 'react'
import LogoSvg from '../../img/LogoSvg'
import classes from './Footer.module.css'

export default function Footer() {
	return (
		<footer>
			<figure className={classes.logo}>
				<LogoSvg />
			</figure>
			<span className={classes.text}>СТУДИЯ МОБИЛЬНОЙ РАЗРАБОТКИ В ОРЛЕ</span>
			<div className={classes.divider}></div>
			<p className={classes.info}>2022 © PHOENIX LAB</p>
		</footer>
	)
}
