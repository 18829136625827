import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { EffectCoverflow, Scrollbar } from 'swiper'

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import classes from './Slider.module.css'

SwiperCore.use([Scrollbar, EffectCoverflow])

export default function Slider(props) {
	return (
		<Swiper
			breakpoints={{
				// when window width is >= 640px
				640: {
					slidesPerView: 2,
				},
				// when window width is >= 768px
				768: {
					slidesPerView: 2,
				},
				1400: {
					spaceBetween: 50,
					slidesPerView: 2,
				},
			}}
			style={{ maxWidth: `${props.width}` }}
			className={classes.mySwiper}
			spaceBetween={50}
			slidesPerView={1}
			scrollbar={{ hide: false }}
			centeredSlides={true}
			effect={'coverflow'}
			watchSlidesProgress={true}
			initialSlide={1}
			coverflowEffect={{
				rotate: 0,
				stretch: 1,
				depth: 290,
				modifier: 1,
				slideShadows: true,
			}}>
			{props.Slider.map((slide, index) => (
				<SwiperSlide className={classes.slide} key={index}>
					<img src={slide} alt="Screen Game" />
				</SwiperSlide>
			))}
		</Swiper>
	)
}
